// _ckeditor.scss

.ck {
    font-family: $font-family-base !important;
}


// Fixes cursor state on toolbar
.ck-button {
    cursor: pointer !important;

    &.ck-disabled {
        cursor: default !important;
    }
}


.ck-editor__editable {
    min-height: 245px !important;
    text-align: center !important;

    .btn {
      background-color: #f15b56 !important;
      border-color: #f15b56 !important;
      font-size: 20px;
      padding: 0.1em 0.5em;
      text-transform: uppercase;
      color: #fff;
      border-radius: 0px;
      text-decoration: none;
    }
}
