/*
Template Name: Steex - Admin & Dashboard Template
Author: Themesbrand
Version: 1.0.0
Website: https://themesbrand.com/
Contact: support@themesbrand.com
File: Main Css File
*/

// Icons
@import "plugins/bootstrap-icons";

//Core files
@import './node_modules/bootstrap/scss/functions';
@import './node_modules/bootstrap/scss/variables';
@import 'variables';
@import 'variables-dark';
@import "./node_modules/bootstrap/scss/bootstrap";
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";
@import 'theme';

// Structure
@import 'structure/topbar';
@import 'structure/page-head';
@import 'structure/footer';
@import 'structure/vertical';
@import 'structure/horizontal';
@import 'structure/two-column';
@import 'structure/layouts';

// Components
@import 'components/avatar';
@import 'components/accordion';
@import 'components/helper';
@import 'components/preloader';
@import 'components/forms';
@import 'components/demos';
@import 'components/print';
@import 'components/ribbons';
@import 'components/toast';
@import 'components/scrollspy';

// Bootstrap components
@import 'components/root';
@import 'components/reboot';
@import 'components/alerts';
@import 'components/badge';
@import 'components/buttons';
@import 'components/breadcrumb';
@import 'components/card';
@import 'components/dropdown';
@import 'components/nav';
@import 'components/table';
@import 'components/modal';
@import 'components/offcanvance';
@import 'components/pagination';
@import 'components/progress';
@import 'components/popover';
@import 'components/type';
@import 'components/form-check';
@import 'components/form-control';
@import 'components/list-group';
@import 'components/tooltip';
@import 'components/colored-links';

// Plugins
@import 'plugins/custom-scrollbar';
@import 'plugins/prismjs';
@import 'plugins/sweetalert2';
@import 'plugins/dropzone';
@import 'plugins/sortablejs';
@import 'plugins/swiper';
@import 'plugins/multijs';
@import 'plugins/colorpicker';
@import 'plugins/form-input-spin';
@import 'plugins/ckeditor';
@import 'plugins/gridjs';
@import 'plugins/listjs';
@import 'plugins/apexcharts';
@import 'plugins/echart';
@import 'plugins/google-map';
@import 'plugins/autocomplete';
@import 'plugins/vector-maps';
@import 'plugins/leaflet-maps';
@import 'plugins/fullcalendar';
@import 'plugins/emoji-picker';
@import 'plugins/datatables';
@import 'plugins/toastify';
@import 'plugins/flatpicker';
@import 'plugins/flag-input';
@import 'plugins/choices';
@import 'plugins/range-slider';
@import 'plugins/card';
@import 'plugins/form-wizard';


